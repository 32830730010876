import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import socialDefault from '../../src/img/careers-social.jpg';
import styled from 'styled-components';
import Hero from '../components/Hero';

const StyledJobPost = styled.div`
  background-color: white;
  .wrap {
    width: calc(100% - 80px);
    max-width: 900px;
    background-color: white;
    /* padding: 0 0 20px; */

    /* box-shadow: 0px 0px 2000px 100px rgba(0, 0, 0, 0.5); */
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;

    margin-left: auto;
    margin-right: auto;
    & > * {
      /* padding: 0 50px; */
      color: black;
    }
  }

  h1 {
    font-size: 2.6rem;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 2.8rem;
    letter-spacing: 2.5px;
    margin-top: 0px;
    transition: all 200ms linear 0s;
    margin-bottom: 40px;
  }
  > div {
    h2 strong,
    h2 {
      font-weight: 500;
      font-size: 1.5rem;
      font-family: 'Oswald';
    }
  }
  p,
  li {
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 300;
  }
  & > .gatsby-image-wrapper {
    padding: 0;
  }

  .button {
    font-family: Oswald;
    display: block;
    margin: 2rem auto;
    padding: 0.5rem 2rem;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 2px;
    border: 1px solid rgb(0, 163, 214);
    color: rgb(0, 163, 214);
    appearance: none;
    border-radius: 0px;
    background: transparent;
    display: inline;
    text-decoration: none;
    :hover {
      text-decoration: underline;
      outline: rgb(0, 163, 214) solid 1px;
    }
  }
`;

const JobPost = ({ data }) => {
  const theTitle =
    data.wpJobPost.seo && data.wpJobPost.seo.title && data.wpJobPost.seo.title > ''
      ? data.wpJobPost.seo.title
      : data.wpJobPost.title + ` | ELEMENT`;
  const meta_items = [];

  meta_items.push({ property: 'og:type', content: 'article' });
  meta_items.push({
    name: 'description',
    content:
      data.wpJobPost.seo && data.wpJobPost.seo.metaDesc && data.wpJobPost.seo.metaDesc > ''
        ? data.wpJobPost.seo.metaDesc
        : theTitle,
  });
  if (data.wpJobPost.seo && data.wpJobPost.seo.opengraphImage && data.wpJobPost.seo.opengraphImage.localFile) {
    meta_items.push({
      property: 'og:image',
      content: data.site.siteMetadata.siteUrl + data.wpJobPost.seo.opengraphImage.localFile.publicURL,
    });
  } else {
    meta_items.push({
      property: 'og:image',
      content: data.site.siteMetadata.siteUrl + socialDefault,
    });
  }

  meta_items.push({ property: 'og:title', content: theTitle });
  meta_items.push({
    property: 'og:url',
    content: data.site.siteMetadata.siteUrl + '/agency-careers/' + data.wpJobPost.slug + '/',
  });

  let hero_img = data.file.childImageSharp.gatsbyImageData;
  if (data.wpJobPost.featuredImage) {
    hero_img = data.wpJobPost.featuredImage.node.localFile.childImageSharp.gatsbyImageData;
  }

  return (
    <Layout>
      <Helmet title={theTitle} meta={meta_items}></Helmet>
      <Hero
        heroImg={hero_img}
        alt_text={data.wpJobPost.featuredImage ? data.wpJobPost.featuredImage.node.altText : ''}
        isBlog={true}
        label={data.wpJobPost.title}
      />

      <StyledJobPost>
        <div className="wrap">
          <h1 dangerouslySetInnerHTML={{ __html: data.wpJobPost.title }} />
          <div dangerouslySetInnerHTML={{ __html: data.wpJobPost.content }} />

          <div style={{ marginTop: '60px', marginBottom: '40px' }}>
            <a
              href={'mailto:Careers@goelement.com?subject=' + encodeURIComponent('Apply: ' + data.wpJobPost.title)}
              className="button"
            >
              Apply Now
            </a>
          </div>
        </div>
      </StyledJobPost>
    </Layout>
  );
};
export default JobPost;

export const pageQuery = graphql`
  query JobPostById($id: String!) {
    wpJobPost(id: { eq: $id }) {
      id
      slug
      title
      content
      featuredImage {
        node {
          sourceUrl
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1920, quality: 93)
            }
          }
        }
      }
      seo {
        title
        metaDesc
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    file(relativePath: { eq: "Hero-WHY.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1639, quality: 93)
      }
    }
  }
`;
